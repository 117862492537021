export const legStatus = [
  { value: 'NA', text: 'NA' },
  { value: 'Citizenship', text: 'Citizenship' },
  { value: 'Permanent Resident', text: 'Permanent Resident' },
  { value: 'Work permit', text: 'Work permit' }
];

export const race = [
  { value: 'NA', text: 'NA' },
  { value: 'Asian', text: 'Asian' },
  { value: 'Caucasion', text: 'Caucasion' },
  { value: 'African', text: 'African' },
  { value: 'Hispanic/Latino', text: 'Hispanic/Latino' },
  { value: 'Native American', text: 'Native American' },
  { value: 'Pacific Islander', text: 'Pacific Islander' },
  { value: 'Other', text: 'Other' },
  { value: 'Declined to provide', text: 'Declined to provide' }
];

export const maritStatus = [
  { value: 'NA', text: 'NA' },
  { value: 'Single', text: 'Single' },
  { value: 'Married', text: 'Married' },
  { value: 'Other', text: 'Other' },
  { value: 'Declined to provide', text: 'Declined to provide' }
];

export const gender = [
  { value: 'Female', text: 'Female' },
  { value: 'Male', text: 'Male' },
  { value: 'Other', text: 'Other' },
  { value: 'Declined to provide', text: 'Declined to provide' }
];

export const position = [
  { value: 'Company', text: 'Company' },
  { value: 'Owner', text: 'Owner' },
  { value: 'Carrier', text: 'Carrier' }
];
export const type = [
  { value: 'IND/SOLE', text: 'IND/SOLE' },
  { value: 'C CORP', text: 'C CORP' },
  { value: 'S CORP', text: 'S CORP' },
  { value: 'PART', text: 'PART' },
  { value: 'TRUST', text: 'TRUST' },
  { value: 'LLC', text: 'LLC' }
];

export const bankType = [
  { value: 'Checking', text: 'Checking' },
  { value: 'Savings', text: 'Savings' }
];
