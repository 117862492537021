






























































































































import { Component, Vue } from 'vue-property-decorator';
import { CompanyModule } from '@/store/index';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import {
  CompanyCategoryToType,
  CompanyType,
  CompanyTypeOptions
} from './company-filter.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';

@Component({
  components: {
    Loading
  }
})
export default class CompanyFilters extends Vue {
  companyModule = CompanyModule;
  loading = false;

  typeFilterDisabled = true;

  statusOptions = [
    { value: null, text: 'All' },
    { value: true, text: 'Active' },
    { value: false, text: 'Inactive' }
  ];

  categoryOptions = [
    { value: null, text: 'ALL' },
    { value: 'customer', text: 'Customer', type: 'customerType' },
    { value: 'vendor', text: 'Vendor', type: 'vendorType' },
    { value: 'CNEE', text: 'CNEE' },
    { value: 'NGL', text: 'NGL' }
  ];

  typeOptions = [...CompanyTypeOptions];

  StatusFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: true,
    field: CompanyType.Status
  });

  CategoryFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: CompanyType.Category
  });

  TypeFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: CompanyType.Type
  });

  NameFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: CompanyType.Name
  });

  constructor() {
    super();
  }

  created() {
    this.search();
  }

  async search() {
    this.loading = true;
    this.$emit('loading', this.loading);
    try {
      console.log([
        this.CategoryFilter.search,
        this.TypeFilter.search,
        this.NameFilter.search
      ]);
      this.companyModule.setFilters(
        TransformFiltersToJson([
          this.CategoryFilter,
          this.TypeFilter,
          this.NameFilter,
          this.StatusFilter
        ])
      );

      await this.companyModule.search();
      this.companyModule.setCompanies({
        data: this.companyModule.companies.filter(company => !!company),
        meta: this.companyModule.meta
      });
    } catch (err) {
      ToastHelper.show('Company search', err.message, 5000, 'danger');
    }

    this.loading = false;
    this.$emit('loading', this.loading);
  }

  resetFilters() {
    this.CategoryFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: CompanyType.Category
    });

    this.TypeFilter = new FilterInput({
      condition: FilterConditions.Equal,
      search: null,
      field: CompanyType.Type
    });

    this.NameFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: CompanyType.Name
    });

    this.companyModule.setFilters(
      TransformFiltersToJson([
        this.CategoryFilter,
        this.TypeFilter,
        this.NameFilter,
        this.StatusFilter
      ])
    );
  }

  categoryFilterChange(value: string) {
    if (!CompanyCategoryToType[value]) {
      this.typeFilterDisabled = true;
      this.TypeFilter.search = null;
      return;
    }

    this.typeFilterDisabled = false;
    this.TypeFilter.field = CompanyCategoryToType[value];

    this.typeOptions = [
      ...CompanyTypeOptions.filter(
        i => i.value === null || i.category === value
      )
    ];
  }
}
