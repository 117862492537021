





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';

@Component({
  components: { Fragment }
})
export default class ToggleButton extends Vue {
  @Prop() format: string;
  @Prop() state: boolean;
}
