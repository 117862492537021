import { render, staticRenderFns } from "./FileDownload.vue?vue&type=template&id=43282e63&scoped=true&"
import script from "./FileDownload.vue?vue&type=script&lang=ts&"
export * from "./FileDownload.vue?vue&type=script&lang=ts&"
import style0 from "./FileDownload.vue?vue&type=style&index=0&id=43282e63&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43282e63",
  null
  
)

export default component.exports