export const categorySelect = [
  { value: 'customer', text: 'CUSTOMER' },
  { value: 'vendor', text: 'VENDOR' },
  { value: 'cnee', text: 'CNEE' },
  { value: 'ngl', text: 'NGL' }
];

export const custTypeSelect = [
  { value: 'FF', text: 'FF' },
  { value: 'SSL', text: 'SSL' },
  { value: 'BCO', text: 'BCO' }
];

export const vendTypeSelect = [
  { value: 'Service Vendor', text: 'Service Vendor' },
  { value: 'Non-Service Vendor', text: 'Non-Service Vendor' }
];

export const termsSelect = [
  { value: 'Prepaid', text: 'Prepaid' },
  { value: 'Due Upon Receipt', text: 'Due Upon Receipt' },
  { value: 'N + 7 days', text: 'N + 7 days' },
  { value: 'N + 15 days', text: 'N + 15 days' },
  { value: 'N + 30 days', text: 'N + 30 days' },
  { value: 'N + 45 days', text: 'N + 45 days' },
  { value: 'N + 60 days', text: 'N + 60 days' }
];

export const w9Select = [
  { value: 'IND/SOLE', text: 'IND/SOLE' },
  { value: 'C CORP', text: 'C CORP' },
  { value: 'S CORP', text: 'S CORP' },
  { value: 'PART', text: 'PART' },
  { value: 'TRUST', text: 'TRUST' },
  { value: 'LLC', text: 'LLC' }
];

