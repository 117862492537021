import { i18n } from '@/i18n';
import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

export const CompanyHeaders: TableHeaders[] = [
  {
    key: 'category',
    label: i18n.tc('mainTable.category')
  },
  {
    key: 'type',
    label: i18n.tc('order.containerType')
  },
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'name',
    label: i18n.tc('mainTable.title')
  },
  {
    key: 'city',
    label: i18n.tc('scheduleTable.city')
  },
  {
    key: 'state',
    label: i18n.tc('mainTable.state')
  },
  {
    key: 'zip',
    label: i18n.tc('mainTable.zip')
  },
  {
    key: 'country',
    label: i18n.tc('mainTable.country')
  },
  {
    key: 'contactName',
    label: i18n.tc('mainTable.contactName')
  },
  {
    key: 'cellPhoneLabel',
    label: i18n.tc('mainTable.cell')
  },
  {
    key: 'officePhoneLabel',
    label: i18n.tc('mainTable.office')
  },
  {
    key: 'email',
    label: i18n.tc('common.email')
  },
  {
    key: 'faxLabel',
    label: i18n.tc('mainTable.fax')
  },
  {
    key: 'createdDateLabel',
    label: i18n.tc('common.date')
  },
  {
    key: 'tracking',
    label: i18n.tc('mainTable.trackingId'),
    content: { type: CellContentType.Switcher }
  },
  {
    key: 'active',
    label: i18n.tc('mainTable.active'),
    content: { type: CellContentType.Switcher }
  }
];
